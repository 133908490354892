// https://github.com/christopher-caldwell/react-kanban/blob/main/docs/props.md#oncarddragend
// https://github.com/christopher-caldwell/react-kanban/tree/main

import { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  ControlledBoard,
  OnDragEndNotification,
  moveCard,
  KanbanBoard,
  UncontrolledBoardProps,
  Card,
} from '@caldwell619/react-kanban';
import '@caldwell619/react-kanban/dist/styles.css';

import { Job, EmploymentType, JobStatus, Maybe, RemoteOption, Board, SeniorityLevel } from '../../../generated/types';
import {
  KanbanApplied,
  KanbanInterview,
  KanbanOffer,
  KanbanRejected,
  KanbanAccepted,
  KanbanWhishList,
} from '../../../components/molecules/Kanban/Kanban';
import { Colors } from '../../../styles/colors';
import { Paper } from '../../../components/atoms/Paper';
import { Copy } from '../../../components/atoms/Typography';
import {
  ApplyIcon,
  DeleteIcon,
  HandShakeIcon,
  SuitcaseIcon,
  TrophyIcon,
  WishIcon,
} from '../../../components/atoms/Icons';
import { toast } from 'react-toastify';

const JobsKanban: React.FC<any> = ({ userUuid, board, jobs, handleCardDrag }: JobsKanbanProps) => {
  const [controlledBoard, setBoard] = useState<KanbanBoard<CustomCard>>({ ...buildData(userUuid, board, jobs) });

  const handleCardMove: OnDragEndNotification<Card> = (card, source, destination) => {
    if ((card?.jobStatus as JobStatus) === JobStatus.Closed) {
      toast.error('You cannot move a closed/rejected job');
      return;
    }

    setBoard((currentBoard) => {
      if (!destination?.toColumnId) {
        return currentBoard;
      }
      const columnId = `${destination.toColumnId}`;
      const cardId = `${card.id}`;

      // Update the job status in DB
      handleCardDrag(cardId, mappingColumns[columnId]);

      const updatedBoard = moveCard(currentBoard, source, destination);
      const updateCards = updatedBoard.columns[(destination?.toColumnId as number) - 1].cards.map(
        (currentCard: any) => {
          if (currentCard.id !== card.id) {
            return currentCard;
          } else {
            return {
              ...currentCard,
              // @ts-ignore-next-line
              jobStatus: mappingColumns[destination?.toColumnId as string],
              updatedAt: new Date().toISOString(),
              ...(mappingColumns[destination?.toColumnId as string] === JobStatus.Applied && {
                appliedAt: new Date().toISOString(),
              }),
              ...(mappingColumns[destination?.toColumnId as string] === JobStatus.Interview && {
                interviewedAt: new Date().toISOString(),
              }),
              ...(mappingColumns[destination?.toColumnId as string] === JobStatus.Offer && {
                offerAt: new Date().toISOString(),
              }),
              ...(mappingColumns[destination?.toColumnId as string] === JobStatus.Accepted && {
                acceptedAt: new Date().toISOString(),
              }),
              ...(mappingColumns[destination?.toColumnId as string] === JobStatus.Closed && {
                rejectedAt: new Date().toISOString(),
              }),
            };
          }
        }
      );
      updatedBoard['columns'][(destination?.toColumnId as number) - 1]['cards'] = [...updateCards];
      return updatedBoard;
    });
  };

  return (
    <MainContainer>
      {/* <pre>{JSON.stringify(controlledBoard, null, 2)}</pre> */}
      <ControlledBoard
        renderColumnHeader={renderColumnHeader}
        // @todo fix this
        renderColumnAdder={() => <></>}
        renderCard={renderCard}
        disableColumnDrag
        onCardDragEnd={handleCardMove}
        allowAddColumn={false}
      >
        {controlledBoard}
      </ControlledBoard>
    </MainContainer>
  );
};

const mappingColumns: Record<string, JobStatus> = {
  '1': JobStatus.Wishlist,
  '2': JobStatus.Applied,
  '3': JobStatus.Interview,
  '4': JobStatus.Offer,
  '5': JobStatus.Accepted,
  '6': JobStatus.Closed,
};

export const renderCard: UncontrolledBoardProps<CustomCard>['renderCard'] = (card) => {
  const lightCard = {
    ...card,
  };
  lightCard.companyAvatar = '';

  let child = (
    <Paper>
      <pre>{JSON.stringify(lightCard, null, 2)}</pre>
    </Paper>
  );
  if (card.jobStatus === JobStatus.Wishlist) {
    child = <KanbanWhishList {...card}></KanbanWhishList>;
  }
  if (card.jobStatus === JobStatus.Applied) {
    child = <KanbanApplied {...card}></KanbanApplied>;
  }
  if (card.jobStatus === JobStatus.Interview) {
    child = <KanbanInterview {...card}></KanbanInterview>;
  }
  if (card.jobStatus === JobStatus.Offer) {
    child = <KanbanOffer {...card}></KanbanOffer>;
  }
  if (card.jobStatus === JobStatus.Accepted) {
    child = <KanbanAccepted {...card}></KanbanAccepted>;
  }
  if (card.jobStatus === JobStatus.Closed) {
    child = <KanbanRejected {...card}></KanbanRejected>;
  }
  return child;
};

export default JobsKanban;

const renderColumnHeader: UncontrolledBoardProps<CustomCard>['renderColumnHeader'] = (column, something) => {
  let icon = <WishIcon size={1.5} />;
  switch (column.id) {
    case 1:
      icon = <WishIcon size={1.5} />;
      break;
    case 2:
      icon = <ApplyIcon size={1.5} />;
      break;
    case 3:
      icon = <SuitcaseIcon size={1.5} />;
      break;
    case 4:
      icon = <TrophyIcon size={1.5} />;
      break;
    case 5:
      icon = <HandShakeIcon size={1.5} />;
      break;
    case 6:
      icon = <DeleteIcon size={1.5} />;
      break;
    default:
      break;
  }
  return (
    <ColumnHeaders>
      <ColumnHeader>
        {icon}
        <ColumnTitle $colorId={column.id}>{column.title}</ColumnTitle>
        <Copy>{column.cards.length}</Copy>
      </ColumnHeader>
    </ColumnHeaders>
  );
};

const ColumnHeaders = styled.div`
  display: flex;
`;

const ColumnHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const ColumnTitle = styled('span')<{ $colorId: number | string }>`
  ${({ $colorId }) => {
    if (`${$colorId}` === '1') {
      return css`
        background-color: ${Colors.ContrastUltraLightest};
      `;
    }
    if (`${$colorId}` === '2') {
      return css`
        background-color: ${Colors.ContrastLightest};
      `;
    }

    if (`${$colorId}` === '3') {
      return css`
        background-color: ${Colors.PrimaryLightest};
      `;
    }

    if (`${$colorId}` === '4') {
      return css`
        background-color: ${Colors.Primary};
      `;
    }

    if (`${$colorId}` === '5') {
      return css`
        background-color: ${Colors.PrimaryDarkest};
      `;
    }

    if (`${$colorId}` === '6') {
      return css`
        background-color: ${Colors.ErrorDark};
      `;
    }
  }};
  margin-right: 10px;
  border-radius: 4px;
  padding: 4px 16px 4px 16px;
`;

const MainContainer = styled.div`
  .react-kanban-column {
    background-color: ${Colors.ContrastUltraLightest};
  }
  .react-kanban-card-adder-button {
    display: none;
  }
`;

const buildData = (userUuid: string, board: any, jobs: Job[]): KanbanBoard<CustomCard> => {
  const wishlist: CustomCard[] = [];
  const applied: CustomCard[] = [];
  const interview: CustomCard[] = [];
  const offer: CustomCard[] = [];
  const accepted: CustomCard[] = [];
  const archived: CustomCard[] = [];
  jobs.forEach((job) => {
    switch (job.status) {
      case JobStatus.Wishlist:
        wishlist.push({
          title: '',
          description: '',
          userUuid: userUuid,
          boardUuid: board.uuid,
          jobUuid: job.uuid,
          id: job.uuid,
          jobTitle: job.jobTitle || '',
          companyName: job.company || '',
          companyWebsite: job.companyWebsite || '',
          companyAvatar: job.companyAvatar || '',
          companyLocationCity: job.companyLocationCity || '',
          agencyName: job.agencyName || '',
          jobUrl: job.jobUrl || '',
          duration: job.duration || '',
          employmentType: job.employmentType,
          remoteOption: job.remoteOption,
          seniorityLevel: job.jobSeniorityLevel,
          rate: job.rate || '',
          jobStatus: job.status,
          updatedAt: job.updatedAt,
          appliedAt: undefined,
          interviewedAt: undefined,
          offerAt: undefined,
          rejectedAt: undefined,
          acceptedAt: undefined,
        });
        break;
      case JobStatus.Applied:
        applied.push({
          title: '',
          description: '',
          userUuid: userUuid,
          boardUuid: board.uuid,
          jobUuid: job.uuid,
          id: job.uuid,
          jobTitle: job.jobTitle || '',
          companyName: job.company || '',
          companyWebsite: job.companyWebsite || '',
          companyAvatar: job.companyAvatar || '',
          companyLocationCity: job.companyLocationCity || '',
          agencyName: job.agencyName || '',
          jobUrl: job.jobUrl || '',
          duration: job.duration || '',
          employmentType: job.employmentType,
          remoteOption: job.remoteOption,
          seniorityLevel: job.jobSeniorityLevel,
          rate: job.rate || '',
          jobStatus: job.status,
          updatedAt: job.updatedAt,
          appliedAt: job.appliedAt,
          interviewedAt: undefined,
          offerAt: undefined,
          rejectedAt: undefined,
          acceptedAt: undefined,
        });
        break;
      case JobStatus.Interview:
        interview.push({
          title: '',
          description: '',
          userUuid: userUuid,
          boardUuid: board.uuid,
          jobUuid: job.uuid,
          id: job.uuid,
          jobTitle: job.jobTitle || '',
          companyName: job.company || '',
          companyWebsite: job.companyWebsite || '',
          companyAvatar: job.companyAvatar || '',
          companyLocationCity: job.companyLocationCity || '',
          agencyName: job.agencyName || '',
          jobUrl: job.jobUrl || '',
          duration: job.duration || '',
          employmentType: job.employmentType,
          remoteOption: job.remoteOption,
          seniorityLevel: job.jobSeniorityLevel,
          rate: job.rate || '',
          jobStatus: job.status,
          updatedAt: job.updatedAt,
          appliedAt: job.appliedAt,
          interviewedAt: job.interviewedAt,
          offerAt: undefined,
          rejectedAt: undefined,
          acceptedAt: undefined,
        });
        break;
      case JobStatus.Offer:
        offer.push({
          title: '',
          description: '',
          userUuid: userUuid,
          boardUuid: board.uuid,
          jobUuid: job.uuid,
          id: job.uuid,
          jobTitle: job.jobTitle || '',
          companyName: job.company || '',
          companyWebsite: job.companyWebsite || '',
          companyAvatar: job.companyAvatar || '',
          companyLocationCity: job.companyLocationCity || '',
          agencyName: job.agencyName || '',
          jobUrl: job.jobUrl || '',
          duration: job.duration || '',
          employmentType: job.employmentType,
          remoteOption: job.remoteOption,
          seniorityLevel: job.jobSeniorityLevel,
          rate: job.rate || '',
          jobStatus: job.status,
          updatedAt: job.updatedAt,
          appliedAt: job.appliedAt,
          interviewedAt: job.interviewedAt,
          offerAt: job.offerAt,
          rejectedAt: undefined,
          acceptedAt: undefined,
        });
        break;
      case JobStatus.Accepted:
        accepted.push({
          title: '',
          description: '',
          userUuid: userUuid,
          boardUuid: board.uuid,
          jobUuid: job.uuid,
          id: job.uuid,
          jobTitle: job.jobTitle || '',
          companyName: job.company || '',
          companyWebsite: job.companyWebsite || '',
          companyAvatar: job.companyAvatar || '',
          companyLocationCity: job.companyLocationCity || '',
          agencyName: job.agencyName || '',
          jobUrl: job.jobUrl || '',
          duration: job.duration || '',
          employmentType: job.employmentType,
          remoteOption: job.remoteOption,
          seniorityLevel: job.jobSeniorityLevel,
          rate: job.rate || '',
          jobStatus: job.status,
          updatedAt: job.updatedAt,
          appliedAt: job.appliedAt,
          interviewedAt: job.interviewedAt,
          offerAt: job.offerAt,
          rejectedAt: undefined,
          acceptedAt: job.acceptedAt,
        });
        break;
      case JobStatus.Closed:
        archived.push({
          title: '',
          description: '',
          userUuid: userUuid,
          boardUuid: board.uuid,
          jobUuid: job.uuid,
          id: job.uuid,
          jobTitle: job.jobTitle || '',
          companyName: job.company || '',
          companyWebsite: job.companyWebsite || '',
          companyAvatar: job.companyAvatar || '',
          companyLocationCity: job.companyLocationCity || '',
          agencyName: job.agencyName || '',
          jobUrl: job.jobUrl || '',
          duration: job.duration || '',
          employmentType: job.employmentType,
          remoteOption: job.remoteOption,
          seniorityLevel: job.jobSeniorityLevel,
          rate: job.rate || '',
          jobStatus: job.status,
          appliedAt: job.appliedAt,
          interviewedAt: job.interviewedAt,
          offerAt: job.offerAt,
          rejectedAt: job.rejectedAt,
          updatedAt: job.updatedAt,
        });
        break;
      default:
        break;
    }
  });

  const columnsData: KanbanBoard<any> = {
    columns: [
      {
        id: 1,
        title: 'Whishlist',
        cards: wishlist,
      },
      {
        id: 2,
        title: 'Applied',
        cards: applied,
      },
      {
        id: 3,
        title: 'Interviewing',
        cards: interview,
      },
      {
        id: 4,
        title: 'Offer',
        cards: offer,
      },
      {
        id: 5,
        title: 'Accepted',
        cards: accepted,
      },
      {
        id: 6,
        title: 'Closed',
        cards: archived,
      },
    ],
  };

  return columnsData;
};

interface JobsKanbanProps {
  userUuid: string;
  board: Board;
  jobs: Job[];
  handleCardOpen: (jobUuid: string) => void;
  handleCardDrag: (jobUuid: string, status: JobStatus) => void;
}
interface CustomCard extends Card {
  id: string;

  userUuid: string;
  boardUuid: string;
  jobUuid: string;

  companyName: string;
  companyWebsite?: string;
  companyAvatar?: string;
  companyLocationCity?: string;
  agencyName?: string;
  jobTitle: string;
  jobUrl?: string;
  jobStatus: JobStatus;
  duration?: string;
  rate?: string;
  employmentType?: Maybe<EmploymentType>;
  remoteOption?: Maybe<RemoteOption>;
  seniorityLevel?: Maybe<SeniorityLevel>;
  appliedAt?: string;
  interviewedAt?: string;
  offerAt?: string;
  rejectedAt?: string;
  acceptedAt?: string;
  updatedAt?: string;
}
