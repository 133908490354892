import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { EmploymentType, JobStatus, Maybe, RemoteOption } from '../../../generated/types';
import { Colors } from '../../../styles/colors';
import { formatDate } from '../../../utils/date';
import { media } from '../../../styles/media';
import { formatUrl } from '../../../utils/url';
import { Urls } from '../../../routes';

import {
  DateIcon,
  ExternalLinkIcon,
  HomeIcon,
  LocationIcon,
  MoneyBillIcon,
  PeopleIcon,
  SuitcaseIcon,
  ViewIcon,
} from '../../atoms/Icons';
import { convertEnumToString } from '../../../utils/enum';

import { Avatar } from '../../atoms/Avatar';

export const Kanban: React.FC<any> = ({ companyName, jobTitle }: KanbanProps) => {
  return (
    <Paper>
      <div>{companyName}</div>
      <div>{jobTitle}</div>
    </Paper>
  );
};

export const KanbanWhishList: React.FC<any> = ({
  userUuid,
  boardUuid,
  jobUuid,
  companyName,
  jobTitle,
  companyWebsite,
  companyLinkedIn,
  companyAvatar,
  duration,
  rate,
  remoteOption,
  employmentType,
  agencyName,
}: KanbanWhishListProps) => {
  return (
    <PaperWrapper elevation={1}>
      <Open>
        <Link to={`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}`}>
          <ViewIcon />
        </Link>
      </Open>
      <Company>
        {Boolean(companyAvatar) && <Avatar image={companyAvatar} />}
        {companyName}
        {companyWebsite && (
          <LocationUrl target="_blank" to={companyWebsite}>
            <ExternalLinkIcon size={1} />
          </LocationUrl>
        )}
      </Company>
      <JobTitle>{jobTitle}</JobTitle>
      {agencyName && (
        <Agency>
          <PeopleIcon size={1} />
          with {agencyName}
        </Agency>
      )}
    </PaperWrapper>
  );
};

export const KanbanApplied: React.FC<any> = ({
  userUuid,
  boardUuid,
  jobUuid,
  companyName,
  jobTitle,
  companyWebsite,
  companyAvatar,
  companyLinkedIn,
  duration,
  rate,
  remoteOption,
  employmentType,
  agencyName,
  appliedAt,
}: KanbanAppliedProps) => {
  const today = new Date().toISOString().slice(0, 10);
  return (
    <PaperWrapper>
      <Open>
        <Link to={`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}`}>
          <ViewIcon />
        </Link>
      </Open>
      <Company>
        {Boolean(companyAvatar) && <Avatar image={companyAvatar} />}
        {companyName}
        {companyWebsite && (
          <LocationUrl target="_blank" to={companyWebsite}>
            <ExternalLinkIcon size={1} />
          </LocationUrl>
        )}
      </Company>
      <JobTitle>{jobTitle}</JobTitle>
      {agencyName && (
        <Agency>
          <PeopleIcon size={1} />
          with {agencyName}
        </Agency>
      )}
      {appliedAt && (
        <FormattedDate>
          {today === appliedAt.slice(0, 10) ? (
            <>
              Applied <Colored>today</Colored>
            </>
          ) : (
            <>
              Applied on <Colored>{formatDate({ date: appliedAt, format: 'short' })}</Colored>
            </>
          )}
        </FormattedDate>
      )}
    </PaperWrapper>
  );
};

export const KanbanInterview: React.FC<any> = ({
  companyName,
  jobTitle,
  companyWebsite,
  companyAvatar,
  companyLinkedIn,
  companyLocationCity,
  userUuid,
  boardUuid,
  jobUuid,
  seniorityLevel,
  interviewedAt,
}: KanbanInterviewedProps) => {
  return (
    <PaperWrapper elevation={5}>
      <Open>
        <Link to={`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}`}>
          <ViewIcon />
        </Link>
      </Open>
      <Company>
        {Boolean(companyAvatar) && <Avatar image={companyAvatar} />}
        {companyName}
        {companyWebsite && (
          <LocationUrl target="_blank" to={companyWebsite}>
            <ExternalLinkIcon size={1} />
          </LocationUrl>
        )}
      </Company>
      <JobTitle>{jobTitle}</JobTitle>
      {(companyLocationCity || companyLocationCity) && (
        <TwoColumnsMinorInfo>
          {companyLocationCity && (
            <LineWrapper>
              <LocationIcon size={1} />
              {companyLocationCity}
            </LineWrapper>
          )}
          {seniorityLevel && (
            <LineWrapper>
              <LocationIcon size={1} />
              {convertEnumToString('SeniorityLevel', seniorityLevel)}
            </LineWrapper>
          )}
        </TwoColumnsMinorInfo>
      )}
    </PaperWrapper>
  );
};

export const KanbanOffer: React.FC<any> = ({
  companyName,
  jobTitle,
  duration,
  rate,
  companyWebsite,
  companyAvatar,
  companyLinkedIn,
  remoteOption,
  employmentType,
  userUuid,
  boardUuid,
  jobUuid,
  offerAt,
}: KanbanOfferProps) => {
  const today = new Date().toISOString().slice(0, 10);
  return (
    <PaperWrapper>
      <Open>
        <Link to={`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}`}>
          <ViewIcon />
        </Link>
      </Open>
      <Company>
        {Boolean(companyAvatar) && <Avatar image={companyAvatar} />}
        {companyName}
        {companyWebsite && (
          <LocationUrl target="_blank" to={companyWebsite}>
            <ExternalLinkIcon size={1} />
          </LocationUrl>
        )}
      </Company>
      <JobTitle>{jobTitle}</JobTitle>
      {(duration || rate) && (
        <TwoColumnsMinorInfo>
          {duration && (
            <Duration>
              <DateIcon size={1} />
              {duration}
            </Duration>
          )}
          {rate && (
            <Rate>
              <MoneyBillIcon size={1} />
              {rate}
            </Rate>
          )}
        </TwoColumnsMinorInfo>
      )}
      {(remoteOption || employmentType) && (
        <TwoColumnsMinorInfo>
          {remoteOption && (
            <Duration>
              <HomeIcon size={1.5} />
              {convertEnumToString('RemoteOption', remoteOption)}
            </Duration>
          )}
          {employmentType && (
            <Rate>
              <SuitcaseIcon size={1.5} />
              {convertEnumToString('EmploymentType', employmentType)}
            </Rate>
          )}
        </TwoColumnsMinorInfo>
      )}
      {offerAt && (
        <FormattedDate>
          {today === offerAt.slice(0, 10) ? (
            <>
              Offer <Colored>today</Colored>
            </>
          ) : (
            <>
              Offer on <Colored>{formatDate({ date: offerAt, format: 'short' })}</Colored>
            </>
          )}
        </FormattedDate>
      )}
    </PaperWrapper>
  );
};

export const KanbanAccepted: React.FC<any> = ({
  companyName,
  jobTitle,
  duration,
  rate,
  companyWebsite,
  companyAvatar,
  companyLinkedIn,
  remoteOption,
  employmentType,
  userUuid,
  boardUuid,
  jobUuid,
  offerAt,
}: KanbanOfferProps) => {
  const today = new Date().toISOString().slice(0, 10);
  return (
    <ColoredPaperWrapper>
      <Open>
        <Link to={`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}`}>
          <ViewIcon />
        </Link>
      </Open>
      <Company>
        {Boolean(companyAvatar) && <Avatar image={companyAvatar} />}
        {companyName}
        {companyWebsite && (
          <LocationUrl target="_blank" to={companyWebsite}>
            <ExternalLinkIcon size={1} />
          </LocationUrl>
        )}
      </Company>
      <JobTitle>{jobTitle}</JobTitle>
      {(duration || rate) && (
        <TwoColumnsMinorInfo>
          {duration && (
            <Duration>
              <DateIcon size={1} />
              {duration}
            </Duration>
          )}
          {rate && (
            <Rate>
              <MoneyBillIcon size={1} />
              {rate}
            </Rate>
          )}
        </TwoColumnsMinorInfo>
      )}
      {(remoteOption || employmentType) && (
        <TwoColumnsMinorInfo>
          {remoteOption && (
            <Duration>
              <HomeIcon size={1.5} />
              {convertEnumToString('RemoteOption', remoteOption)}
            </Duration>
          )}
          {employmentType && (
            <Rate>
              <SuitcaseIcon size={1.5} />
              {convertEnumToString('EmploymentType', employmentType)}
            </Rate>
          )}
        </TwoColumnsMinorInfo>
      )}
      {offerAt && (
        <FormattedDate>
          {today === offerAt.slice(0, 10) ? (
            <>
              Offer <Colored>today</Colored>
            </>
          ) : (
            <>
              Offer on <Colored>{formatDate({ date: offerAt, format: 'short' })}</Colored>
            </>
          )}
        </FormattedDate>
      )}
    </ColoredPaperWrapper>
  );
};

export const KanbanRejected: React.FC<any> = ({
  companyName,
  companyWebsite,
  companyAvatar,
  companyLinkedIn,
  jobTitle,
  agencyName,
  userUuid,
  boardUuid,
  jobUuid,
  rejectedAt,
}: KanbanRejectedProps) => {
  const today = new Date().toISOString().slice(0, 10);
  return (
    <PaperWrapper>
      <Rejected>
        <Open>
          <Link to={`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}`}>
            <ViewIcon />
          </Link>
        </Open>
        <Company>
          {Boolean(companyAvatar) && <Avatar image={companyAvatar} />}
          {companyName}
          {companyWebsite && (
            <LineWrapper>
              <LocationUrl target="_blank" to={companyWebsite}>
                <ExternalLinkIcon size={1} />
              </LocationUrl>
            </LineWrapper>
          )}
        </Company>
        <JobTitle>{jobTitle}</JobTitle>
        {agencyName && (
          <LineWrapper>
            <PeopleIcon size={1} />
            {agencyName}
          </LineWrapper>
        )}
        {rejectedAt && (
          <FormattedDate>
            {today === rejectedAt.slice(0, 10) ? (
              <>
                Closed <Colored>today</Colored>
              </>
            ) : (
              <>
                Closed on <Colored>{formatDate({ date: rejectedAt, format: 'short' })}</Colored>
              </>
            )}
          </FormattedDate>
        )}
      </Rejected>
    </PaperWrapper>
  );
};

const Colored = styled.span`
  font-weight: 600;
  color: ${Colors.ContrastDarkest};
`;

const FormattedDate = styled.div`
  font-size: 0.7rem;
  font-weight: 400;
`;

const PaperWrapper = styled(Paper)`
  position: relative;
  padding: 4px;
  width: 300px;

  ${media.from_tablet`
    padding: 8px;
  `}

  margin-block: 16px;
`;

const ColoredPaperWrapper = styled(Paper)`
  background-color: ${Colors.PrimaryUltraLightest}!important;
  position: relative;
  padding: 4px;
  width: 300px;

  ${media.from_tablet`
    padding: 8px;
  `}

  margin-block: 16px;
`;

const Agency = styled.div`
  display: flex;
  font-size: 0.7rem;
  font-weight: 300;
  margin-bottom: 8px;
  gap: 4px;
`;

const Open = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const Company = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
`;

const LocationUrl = styled(Link)`
  transform: translateY(-3px);
`;

const JobTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0px;
`;

const TwoColumnsMinorInfo = styled.div`
  display: flex;
  font-size: 0.8rem;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const Duration = styled.div`
  display: flex;
  gap: 4px;
`;

const Rate = styled.div`
  display: flex;
  gap: 4px;
`;

const Rejected = styled.div`
  color: ${Colors.GreyLightest};
  opacity: 0.5;
`;

const LineWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface KanbanProps {
  userUuid: string;
  boardUuid: string;
  jobUuid: string;
  companyName: string;
  companyWebsite?: string;
  companyAvatar?: string;
  companyLinkedIn?: string;
  companyLocationCity?: string;
  agencyName?: string;
  jobTitle: string;
  jobUrl?: string;
  jobStatus: JobStatus;
  duration?: string;
  rate?: string;
  employmentType?: Maybe<EmploymentType>;
  remoteOption?: Maybe<RemoteOption>;
}

interface KanbanWhishListProps extends KanbanProps {}

interface KanbanAppliedProps extends KanbanProps {
  appliedAt: string;
}

interface KanbanInterviewedProps extends KanbanProps {
  interviewedAt: string;
}

interface KanbanOfferProps extends KanbanProps {
  offerAt: string;
}

interface KanbanRejectedProps extends KanbanProps {
  rejectedAt: string;
}
